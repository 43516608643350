@import url(https://fonts.googleapis.com/css2?family=Parisienne&family=Poiret+One&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.App {
    height: 100vh;
    display: -webkit-flexbox;
    display: flex;
    -webkit-flex-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

h1 {
    text-align: center;
    font-family: "Poiret One", cursive;
    font-size: 3rem;
    margin: 0;
}

span {
    font-family: "Parisienne", cursive;
    font-size: 6rem;
    font-weight: 100;
}

h2 {
    font-size: 1rem;
    font-family: "Poiret One", cursive;
}

