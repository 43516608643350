@import url("https://fonts.googleapis.com/css2?family=Parisienne&family=Poiret+One&display=swap");

.App {
    height: 100vh;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

h1 {
    text-align: center;
    font-family: "Poiret One", cursive;
    font-size: 3rem;
    margin: 0;
}

span {
    font-family: "Parisienne", cursive;
    font-size: 6rem;
    font-weight: 100;
}

h2 {
    font-size: 1rem;
    font-family: "Poiret One", cursive;
}
